<template>
    <b-form-group
        :label="$t('Contact Phones')"
        label-for="contact-phones"
    >
      <b-form
          id="contact-phones"
        ref="form"
        style="height: auto"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <div v-if="phones">

          <div
            v-for="(item, index) in phones"
            :id="index"
            :key="index"
            ref="row"
            class="mb-1 d-flex align-items-start justify-content-center"
          >
            <div>
              <v-select
                :id="item.value"
                v-model="item.country_code"
                :options="optionsDialCodes"
                label="item_data"
                :reduce="(text) => text.country_code"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                placeholder="+"
                class="country-code"
                @input="(val) => onPhoneCountryCodeUpdate(val, index)"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes" />
                </template>
                <template #option="{ country_code}">
                  <span class="text-wrap">+{{ country_code }}</span>
                </template>
                <template #selected-option="{ country_code}">
                  <span>+{{ country_code }}</span>
                </template>
              </v-select>
            </div>
            <div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Phone')"
                :vid="`phone${index}`"
                :rules="`required|${getPhoneRule(item)}`"
              >

                <b-input-group
                  class="input-group-merge"
                  :name="$t('Phone')"
                >
                  <b-form-input
                    :id="`phone${index}`"
                    v-model="item.value"
                    v-mask="item.mask"
                    type="text"
                    :name="`phone${index}`"
                    :required="true"
                    :disabled="!item.country_code"
                    :placeholder="item.placeholder"
                    @input="(val) => onPhoneUpdate(val, index)"

                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      block
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-checkbox-group
                v-model="phones[index].messengers"
                buttons
                class="messengers"
                @change="
                  (val) => (accountData.phones[index].messengers = val)
                "
              >
                <template v-for="option in optionMessengers">
                  <b-form-checkbox
                    :key="option.slug"
                    :value="option.slug"
                    class="btn-icon rounded-circle"
                    button-variant="flat-primary"
                    button
                  >
                    <b-avatar
                      :src="option.icon"
                      size="sm"
                      alt="messenger"
                      variant="light-primary"
                    />
                  </b-form-checkbox>
                </template>
              </b-form-checkbox-group>
            </div>
          </div>
        </div>
      </b-form>
      <div class="text-center">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"

            variant="outline-primary"
            class="mt-1"
            @click="repeateAgain"
        >
          <feather-icon
              class="mr-50"
              icon="PlusCircleIcon"
          />
          <span class="align-middle">{{ $t("Add") }}</span>
        </b-button>
      </div>
    </b-form-group>
  </template>

<script>
import {
  BAvatar,
  BButton,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { showToast } from '@/mixins/notification/toasts'
import { heightTransition } from '@core/mixins/ui/transition'
import { extend, ValidationProvider } from 'vee-validate'
import { digits, max, required } from '@validations'
import { numbersOnly } from '@/mixins/functions'

import { locales } from '@/mixins/options'
import { ref, watch } from '@vue/composition-api'
import { useUserLocation, useUserUi } from '@/views/apps/user/useUser'
import { countries } from '@/utils/location/phoneCodeCountries'
import i18n from '@/libs/i18n'

extend('between', {
  validate(value, args) {
    const { length } = value.replace(/\D/g, '')
    return length >= args.min && length <= args.max
  },
  params: ['min', 'max'],
  message: (_, values) => i18n.t('validations.messages.between', values),
})
extend('digits', {
  validate(value, args) {
    const { length } = value.replace(/\D/g, '')
    return length.toString() === args.digits
  },
  params: ['digits'],
  message: (_, values) => i18n.t('validations.messages.digits', values),
})
export default {
  components: {
    ValidationProvider,
    vSelect,
    BForm,
    BFormGroup,
    BAvatar,
    BButton,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast, heightTransition, numbersOnly],
  props: {
    accountData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {
      nextTodoId: 0,
      required,
      digits,
      max,
      showCropper: false,
    }
  },

  methods: {
    onPhoneUpdate(phone, index) {
      if (this.accountData.phones[index] && this.accountData.phones[index].value !== phone) {
        this.accountData.phones[index].value = phone.replace(/\D/g, '')
        this.accountData.phones[index].country_code = this.phones[index].country_code
      } else {
        this.accountData.phones.push({ value: phone.replace(/\D/g, ''), country_code: this.phones[index].country_code })
      }
    },
    onPhoneCountryCodeUpdate(countryCode, index) {
      this.phones[index].country_code = countryCode
      this.phones[index].mask = this.getPhoneMask(countryCode)
      this.phones[index].placeholder = this.getPlaceholder(countryCode)
      this.phones[index].digits = this.getDigits(countryCode)
      this.phones[index].messengers = []
    },

    repeateAgain() {
      this.nextTodoId += 1
      this.phones.push({
        value: '',
        valid: false,
        country_code: '',
      })
    },

    removeItem(index) {
      this.phones.splice(index, 1)
      this.accountData.phones.splice(index, 1)
    },

  },
  setup(props) {
    const phones = ref([])
    const availableCountries = countries

    const getPhoneMask = dialCode => {
      const countryObj = availableCountries.find(country => country.country_code === dialCode)
      return countryObj ? countryObj.mask : ''
    }

    const getPlaceholder = dialCode => {
      const countryObj = availableCountries.find(country => country.country_code === dialCode)
      return countryObj ? countryObj.placeholder : ''
    }

    const getDigits = dialCode => {
      const countryObj = availableCountries.find(country => country.country_code === dialCode)
      return countryObj ? countryObj.digits : ''
    }

    watch(
      () => props.accountData,
      () => {
        if (props.accountData.phones) {
          props.accountData.phones.forEach((item, index) => {
            const countryObj = availableCountries.find(el => el.country_code === item.country_code)
            const phoneObj = {
              value: item.value,
              valid: false,
              messengers: item.messengers,
            }
            phones.value.push({ ...phoneObj, ...countryObj })
          })
        }
      },
    )

    const optionCountries = store.getters['appOptions/countries']

    const {
      localesList,
      countriesList,
    } = locales()
    const { optionMessengers } = useUserUi()

    const optionLanguages = localesList
    const { userCountryCode } = useUserLocation()

    const getPhoneRule = phoneObj => {
      if (!phoneObj.country_code) return ''

      if (phoneObj.digits.includes(',')) {
        return 'between:'+phoneObj.digits
      }
      const digits = phoneObj.digits - phoneObj.country_code.length
      return 'digits:'+digits
    }

    const optionsDialCodes = availableCountries.map(country => ({
      iso2: country.iso2,
      country_code: country.country_code,
      item_data: country.iso2 + country.country_code,
    }))

    return {
      phones,
      optionCountries,
      optionLanguages,
      optionMessengers,
      userCountryCode,
      countriesList,
      optionsDialCodes,
      getPhoneMask,
      getPhoneRule,
      getPlaceholder,
      getDigits,
    }
  },
}
</script>
<style lang="scss" >
.country-code{
  width: 82px;
}
</style>
