var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"2"}},[_c('b-form-group',[_c('span',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t("Avatar")))]),_c('account-setting-user-avatar',{attrs:{"account-data":_vm.accountData}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"8","lg":"10"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Full Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Full Name'),"label-for":"full-name"}},[_c('b-form-input',{attrs:{"id":"full-name"},model:{value:(_vm.accountData.name),callback:function ($$v) {_vm.$set(_vm.accountData, "name", $$v)},expression:"accountData.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',[_c('div',{staticClass:"text-left small"},[_vm._v(" "+_vm._s(_vm.$t("user.i_speak_languages"))+" ")]),_c('v-select',{attrs:{"options":_vm.optionLanguages,"reduce":function (text) { return text.label; },"label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.accountData.languages),callback:function ($$v) {_vm.$set(_vm.accountData, "languages", $$v)},expression:"accountData.languages"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('About Me'),"rules":"max:230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"bio-area","label":_vm.$t('About Me')}},[_c('b-form-textarea',{attrs:{"id":"bio-area","rows":"6","placeholder":_vm.$t('Your bio data here')},model:{value:(_vm.accountData.bio),callback:function ($$v) {_vm.$set(_vm.accountData, "bio", $$v)},expression:"accountData.bio"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Username'),"label-for":"phone"}},[_c('b-form-input',{attrs:{"id":"phone","disabled":""},model:{value:(_vm.accountData.phone),callback:function ($$v) {_vm.$set(_vm.accountData, "phone", $$v)},expression:"accountData.phone"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null},model:{value:(_vm.accountData.email),callback:function ($$v) {_vm.$set(_vm.accountData, "email", $$v)},expression:"accountData.email"}}),(!_vm.accountData.email)?_c('b-alert',{staticClass:"mb-0",attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"InfoIcon"}}),_c('small',[_vm._v(_vm._s(_vm.$t('The email address is required for resetting a lost or forgotten password')))])],1)]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('span',{staticClass:"text-left small"},[_vm._v(_vm._s(_vm.$t('Language')))]),_c('v-select',{attrs:{"id":"locale","options":_vm.localesList,"reduce":function (name){ return name.locale; },"clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.accountData.lang),callback:function ($$v) {_vm.$set(_vm.accountData, "lang", $$v)},expression:"accountData.lang"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('currency-selector',{staticClass:"pb-1",attrs:{"currency":_vm.accountData.currency},on:{"currency-selected":function (currency){ return _vm.accountData.currency=currency; }}})],1),_c('b-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('phones-input',{attrs:{"account-data":_vm.accountData}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }