<template>
  <div>
    <span class="text-left small">{{ $t('common.currency') }}</span>
    <v-select
      id="currencySelector"
      v-model="currencySelected"
      :options="currencyList"
      :reduce="currency=>currency.currency"
      :clearable="false"
    >
      <template #option="{ symbol, currency}">
        <span> {{ symbol }} {{ currency }}</span>
      </template>
      <template #selected-option="{ symbol, currency}">
        <span> {{ symbol }} {{ currency }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import { currency } from '@/mixins/options'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },

  props: {
    currency: {
      type: String,
      required: false,
      default: process.env.VUE_APP_CURRENCY,
    },
  },

  setup(props, { emit }) {
    const { currencyList } = currency()
    const currencySelected = computed({
      get: () => props.currency || process.env.VUE_APP_CURRENCY,
      set: val => {
        emit('currency-selected', val)
      },
    })
    return {
      currencyList,
      currencySelected,
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
