<template>
  <div>
    <!--    <b-card-title> Map </b-card-title>-->
    <b-link
      @click="showModal"
    >
      <span class="align-middle">
        {{ getFormattedAddress(data.address) }}
      </span>
    </b-link>
    <b-modal
      id="map-modal"
      ref="map-modal"
      centered
      hide-footer
      :title="`${$t('Within a radius of')+' '+location.radius/1000+' '+$t('km') }`"
    >
      <l-map
        ref="map"
        :center="center"
        :options="map.options"
        style="height: 240px; width: 100%"
      >
        <l-tile-layer :url="tileProvider.url" />
        <l-marker
          visible
          :lat-lng.sync="center"
        />

        <l-circle
          v-if="location.radius"
          :lat-lng.sync="location.position"
          :radius="location.radius"
          color="red"
        />

        <l-circle
          v-if="location.viewportRadius"
          :lat-lng.sync="location.viewportPosition"
          :radius="location.viewportRadius"
          fill-color="#ff9f43"
          :fill-opacity="0.4"
          :weight="0"
        />
      </l-map>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BLink, VBModal,
} from 'bootstrap-vue'
import { Icon, latLngBounds } from 'leaflet'
import {
  LMap, LTileLayer, LMarker, LCircle,
} from 'vue2-leaflet'

import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/dist/geosearch.css'
import store from '@/store'
import { nextTick } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { getFormattedAddress } from '@/mixins/functions'
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  // eslint-disable-next-line global-require
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  // eslint-disable-next-line global-require
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
export default {
  components: {
    BCard,
    BLink,

    LMap,
    LTileLayer,
    LMarker,
    LCircle,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [getFormattedAddress],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    defaultLocation: {
      type: Object,
      default: () => ({
        lat: 49.444444,
        lng: 32.059722,
      }),
    },
    viewport: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      point: {},
      location: {
        radius: 0,
        viewportRadius: 0,
        viewportPosition: {
          lat: null,
          lng: null,
        },
        viewportBounds: {},
      },
      map: {
        options: {
          zoomControl: true,
          attributionControl: false,
          zoomSnap: true,
          zoom: 5,
          minZoom: 6,
          maxZoom: 18,
          tap: false,
          dragging: false,
          scrollWheelZoom: false,
        },
      },
      tileProvider: {
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      },

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = 'rtl'
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = 'ltr'
      return this.dir
    },
    center() {
      if (this.viewport.length) {
        return this.location.viewportPosition
      }
      return [
        this.location.position.lat || this.defaultLocation.lat,
        this.location.position.lng || this.defaultLocation.lng,
      ]
    },

  },
  watch: {
    viewport: {
      handler(val) {
        if (val.length) {
          this.updateLocationViewport()
        }
      },
    },
    data: {
      deep: true,
      immediate: true,
      handler(val) {
        this.point = val
        this.setPosition(this.point.lat, this.point.lng)
        this.setRadius()
      },
    },
    'location.position': {
      handler() {
        this.point.lng = this.location.position.lng
        this.point.lat = this.location.position.lat
      },
    },
  },

  methods: {
    showModal() {
      this.$refs['map-modal'].show()
      nextTick()
      this.rerenderMap()
    },
    rerenderMap() {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    setPosition(lat, lng) {
      this.location.position = { lat, lng }
    },
    setRadius() {
      this.location.radius = this.point.location_radius * 1000
    },
    updateLocationViewport() {
      this.viewportBounds = latLngBounds(this.viewport)
      const center = this.viewportBounds.getCenter()
      const mapBoundNorthEast = this.viewportBounds.getNorthEast()
      const mapDistance = mapBoundNorthEast.distanceTo(center)
      this.location.viewportRadius = mapDistance / 1.26
      this.location.viewportPosition = { lat: center.lat, lng: center.lng }
    },
  },

}
</script>
<style scoped>
.leaflet-container{
  z-index: 0;
}
</style>
