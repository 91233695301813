import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'
import { useToast } from 'vue-toastification/composition'

export const showToast = {
  methods: {
    showToast(response, element, title = null, customSuccessText = null, timeout = 2000) {
      let icon
      let text
      let variant
      switch (response.status) {
        case 'success':
        case 200:
        case 201:
          icon = 'BellIcon'
          text = this.$t(customSuccessText) || `👋 ${this.$t(element)} - ${this.$t(response.statusText)}`
          variant = 'success'
          break
        case 'error':
          icon = 'AlertOctagonIcon'
          text = ` ${this.$t(element)} - ${this.$t(response.statusText)}`
          variant = 'danger'
          break
        default:
          icon = 'AlertCircleIcon'
          text = `${this.$t(element)} - ${this.$t(response.statusText)}`
          variant = 'warning'
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t(title) || this.$t('Notification'),
          icon,
          text,
          variant,
        },
      },
      {
        timeout,
      })
    },
  },
}

export const customToast = () => {
  const toast = useToast()
  const showCustomToast = (response, element, title = null, customSuccessText = null, timeout = 2000) => {
    let icon
    let text
    let variant
    switch (response.status) {
      case 'success':
      case 200:
      case 201:
        icon = 'BellIcon'
        text = i18n.t(customSuccessText) || `👋 ${i18n.t(element)} - ${i18n.t(response.statusText)}`
        variant = 'success'
        break
      case 'error':
        icon = 'AlertOctagonIcon'
        text = ` ${i18n.t(element)} - ${i18n.t(response.statusText)}`
        variant = 'danger'
        break
      default:
        icon = 'AlertCircleIcon'
        text = `${i18n.t(element)} - ${i18n.t(response.statusText)}`
        variant = 'warning'
    }
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t(title) || i18n.t('Notification'),
        icon,
        text,
        variant,
      },
    },
    {
      timeout,
    })
  }
  return {
    showCustomToast,
  }
}
export const _ = null
