import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(utc)

export const fromNowDate = date => {
  if (!date) {
    return null
  }
  return dayjs(date).fromNow()
}
export const formatDateTime = (date, format = 'DD-MM-YY HH:mm') => {
  if (!date) {
    return null
  }
  return dayjs(date).format(format)
}
export const formatDateTimeUTC = (date, format = 'DD-MM-YY HH:mm') => {
  if (!date) {
    return null
  }
  return dayjs(date).utc().format(format)
}

export const secondsToTimer = seconds => {
  const totalMs = seconds * 1000
  return new Date(totalMs).toISOString().slice(11, 19)
}

export const fromNowDateTime = date => {
  if (!date) {
    return null
  }
  return dayjs().calendar(dayjs(date))
}
