<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('I provide services')"
          label-for="serviceProvider"
        >
          <b-form-checkbox
            id="serviceProvider"
            v-model="statusServiceProvider"
            :unchecked-value="false"
            value="service_provider"
            name="check-button"
            switch
            inline
            class="pt-1"
          >
            <span v-if="statusServiceProvider">{{ $t('common.yes') }}</span>
            <span v-if="!statusServiceProvider">{{ $t('common.no') }}</span>
          </b-form-checkbox>

        </b-form-group>
      </b-col>
      <b-col>
        <currency-selector
          v-if="statusServiceProvider"
          :currency="accountData.currency"
          class="pb-1"
          @currency-selected="setCurrency"
        />
      </b-col>
    </b-row>

    <offer-list-simple v-if="statusServiceProvider" />

  </div>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/mixins/notification/toasts'
import OfferListSimple from '@/views/apps/service/offer/list/OfferListSimple.vue'
import CurrencySelector from '@/layouts/components/CurrencySelector.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    OfferListSimple,
    CurrencySelector,

  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusServiceProvider: {
      get() {
        return this.accountData.services.includes('service_provider') ? 'service_provider' : false
      },
      set(newValue) {
        this.setService(newValue, 'service_provider')
      },
    },
  },
  methods: {

    setService(value, slug) {
      if (value === false) {
        const index = this.accountData.services.indexOf(slug)
        if (index > -1) {
          this.accountData.services.splice(index, 1)
        }
      } else if (!this.accountData.services.includes(slug)) {
        this.accountData.services.push(value)
      }
    },
    setCurrency(currencyCode) {
      this.accountData.currency = currencyCode
    },
  },

}
</script>
