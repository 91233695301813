<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="4"
          lg="2"
        >
          <b-form-group>
            <span class="text-left small">{{ $t("Avatar") }}</span>
            <account-setting-user-avatar
              :account-data="accountData"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="8"
          lg="10"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              lg="6"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Full Name')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Full Name')"
                  label-for="full-name"
                >
                  <b-form-input
                    id="full-name"
                    v-model="accountData.name"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group>
                <div class="text-left small">
                  {{ $t("user.i_speak_languages") }}
                </div>
                <v-select
                  v-model="accountData.languages"
                  :options="optionLanguages"
                  :reduce="(text) => text.label"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                >
                  <template #option="{ name }">
                    <span> {{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('About Me')"
                rules="max:230"
              >
                <b-form-group
                  label-for="bio-area"
                  :label="$t('About Me')"
                >
                  <b-form-textarea
                    id="bio-area"
                    v-model="accountData.bio"
                    rows="6"
                    :placeholder="$t('Your bio data here')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="12"
              lg="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    :label="$t('Username')"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="accountData.phone"
                      disabled
                    />

                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Email')"
                    rules="email"
                  >
                    <b-form-group
                      :label="$t('Email')"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="accountData.email"
                        type="email"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-alert
                        v-if="!accountData.email"
                        variant="danger"
                        class="mb-0"
                        show
                      >
                        <div class="alert-body">
                          <feather-icon
                            icon="InfoIcon"
                            class="mr-50"
                          />
                          <small>{{ $t('The email address is required for resetting a lost or forgotten password') }}</small>
                        </div>
                      </b-alert>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>

                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <span class="text-left small">{{ $t('Language') }}</span>
                    <v-select
                      id="locale"
                      v-model="accountData.lang"
                      :options="localesList"
                      :reduce="name=>name.locale"
                      :clearable="false"
                    >
                      <template #option="{ name}">
                        <span> {{ name }}</span>
                      </template>
                      <template #selected-option="{ name }">
                        <span> {{ name }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >

                  <currency-selector
                    :currency="accountData.currency"
                    class="pb-1"
                    @currency-selected="(currency)=>accountData.currency=currency"
                  />

                </b-col>
                <b-col
                  cols="12"
                  md="8"
                  offset-md="2"
                >
                  <phones-input :account-data="accountData" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BAlert, BCard,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { currency, locales } from '@/mixins/options'
import { ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import CurrencySelector from '@/layouts/components/CurrencySelector.vue'
import AccountSettingUserAvatar from '@/views/pages/account-setting/AccountSettingUserAvatar.vue'
import PhonesInput from '@/views/pages/account-setting/components/PhonesInput.vue'
import useUsersList from '../../apps/user/users-list/useUsersList'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BAlert,
    BCard,
    vSelect,
    ValidationProvider,
    CurrencySelector,
    AccountSettingUserAvatar,
    PhonesInput,
  },

  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { resolveUserRoleVariant } = useUsersList()
    const { localesList } = locales()
    const { getCountryCurrency, currencyList } = currency()

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.data.avatar = base64
    })

    const optionLanguages = localesList
    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      permissionsData,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      localesList,
      currencyList,
      optionLanguages,

    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
