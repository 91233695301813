<template>
  <section id="notifications">
    <b-row v-if="!notificationsEnabled()">
      <b-col
        md="8"
        offset-sm="2"
      >
        <!-- alert -->
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            {{ $t('The notification permission is required') }}
          </h4>
          <div class="alert-body">
            <p>{{ $t('Please provide notification permission') }}</p>
          </div>
        </b-alert>
        <b-alert
          v-if="notificationsPermissions==='denied'"
          variant="warning"
          show
        >
          <h4 class="alert-heading">
            {{ $t('Permission has been blocked as you ignore the permission prompt several times or denied permission') }}
          </h4>
          <div class="alert-body">
            <p>{{ $t('This can be reset in Page Info which can be accessed by clicking the lock icon next to the URL') }}

            </p><p>
              {{ $t('See for more information:') }}
              <b-link
                href="https://support.google.com/chrome/answer/3220216"
                target="_blank"
                class="alert-link"
              > https://support.google.com/chrome/answer/3220216</b-link>) }}
            </p>
          </div>
        </b-alert>
        <!--/ alert -->
        <div class="text-center"><b-button
          variant="primary"
          :disabled="notificationsPermissions==='denied'"
          @click="requestPermission"
        >
          <feather-icon
            icon="BellIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ $t('Provide Permission') }}</span>

        </b-button>
        </div></b-col>
    </b-row>

    <b-row
      v-if="notificationsEnabled()"
      class="match-height pt-1"
    >
      <b-col
        lg="4"
        md="6"
      >
        <b-card
          no-body
          class="pl-0 pr-0"
        >
          <div
            class="rounded-top text-center bg-light-primary"
          >
            <b-img
              :src="require('@/assets/images/svg/availability.svg')"
              :alt="$t('Web Notification')"
              height="150"
            />
          </div>
          <b-card-header>
            <b-card-title>
              {{ $t('Web Notification') }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-text>
              <b-form-checkbox
                id="web"
                v-model="webNotifications"
                :unchecked-value="false"
                value="web"
                name="web"
                switch
                inline
                :disabled="true"
              >
                <span v-if="webNotifications">{{ $t('Active') }}</span>
              </b-form-checkbox>
            </b-card-text>
            <div
              :class="!webNotifications?'text-muted':''"
            >
              <b-card-text class="desc">
                <span>{{ $t('Notifications, that you can see at the top menu after logging into the site. To view notifications, you need to click on the bell icon') }}</span>
              </b-card-text>
              <b-card-footer>
                <ul class="pl-0">
                  <li>
                    {{ $t('New Proposals') }}
                  </li>
                  <li>
                    {{ $t('New Requests') }} <small class="text-nowrap">( {{ $t('For service providers') }} )</small>
                  </li>
                  <li>
                    {{ $t('System messages') }}
                  </li>
                </ul>
              </b-card-footer>
            </div>

          </b-card-body>
          <!--          <b-alert-->
          <!--            show-->
          <!--            variant="warning"-->
          <!--          >-->
          <!--            <div class="alert-body">-->
          <!--              <feather-icon-->
          <!--                class="mr-25"-->
          <!--                icon="InfoIcon"-->
          <!--                size="16"-->
          <!--              />-->
          <!--              <span class="ml-25">{{ $t(`Under development. We are working hard on making it available in the next release`) }}</span>-->
          <!--            </div>-->
          <!--          </b-alert>-->
        </b-card>
      </b-col>
      <b-col
        lg="4"
        md="6"
      >
        <b-card
          no-body
        >
          <div
            class="rounded-top text-center bg-light-primary"
          >
            <b-img
              :src="require('@/assets/images/svg/simplicity.svg')"
              :alt="$t('Push Notification')"
              height="150"
            />
          </div>
          <b-card-header>
            <b-card-title>
              {{ $t('Push Notification') }}
            </b-card-title>
            <!--            <b-badge-->
            <!--              v-if="!appInstalled"-->
            <!--              pill-->
            <!--              variant="light-info"-->
            <!--            >-->
            <!--              {{ $t('App installation required') }}-->
            <!--            </b-badge>-->
          </b-card-header>
          <b-card-body :class="!pushNotifications?'text-muted':''">
            <b-card-text>
              <b-form-checkbox
                id="push"
                v-model="pushNotifications"
                :unchecked-value="false"
                value="push"
                name="push"
                switch
                inline
              >
                <span v-if="pushNotifications">{{ $t('Active') }}</span>
              </b-form-checkbox>
            </b-card-text>
            <b-card-text class="desc">
              {{ $t('Push notifications are small pop-up messages that are sent to your devices and appear on your screen even when your browser is closed') }}
            </b-card-text>
            <b-card-footer>
              <ul class="pl-0">
                <li>{{ $t('New Proposals') }}</li>
                <li>{{ $t('New Requests') }} <small class="text-nowrap">( {{ $t('For service providers') }} )</small></li>
                <li>{{ $t('System messages') }}</li>
              </ul>
            </b-card-footer>
          </b-card-body>
          <!--          <b-alert-->
          <!--            show-->
          <!--            variant="warning"-->
          <!--          >-->
          <!--            <div class="alert-body">-->
          <!--              <feather-icon-->
          <!--                class="mr-25"-->
          <!--                icon="InfoIcon"-->
          <!--                size="16"-->
          <!--              />-->
          <!--              <span class="ml-25">{{ $t(`Under development. We are working hard on making it available in the next release`) }}</span>-->
          <!--            </div>-->
          <!--          </b-alert>-->
        </b-card>
      </b-col>

      <b-col
        lg="4"
        md="6"
      >
        <b-card
          no-body
        >
          <div
            class="rounded-top text-center bg-light-primary"
          >
            <b-img
              :src="require('@/assets/images/illustration/sales.svg')"
              :alt="$t('SMS Notification')"
              height="150"
            />
          </div>
          <b-card-header>
            <b-card-title class="text-muted">
              {{ $t('SMS Notification') }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-card-text>
              <b-form-checkbox
                id="sms"
                v-model="smsNotifications"
                :unchecked-value="false"
                value="sms"
                name="sms"
                switch
                inline
                :disabled="true"
              >
                <span v-if="smsNotifications">{{ $t('Active') }}</span>
              </b-form-checkbox>
            </b-card-text>
            <b-card-text class="text-muted">
              <span>{{ $t('This type of notification is not currently available. Please message us if you would like to have this feature in future service releases') }}</span>
            </b-card-text>

          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BFormCheckbox, BCard, BCardText, BCardBody, BCardTitle, BCardFooter, BAlert, BLink, BButton, BCardHeader, BBadge, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { showToast } from '@/mixins/notification/toasts'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardFooter,
    BAlert,
    BLink,
    BButton,

    BCardHeader,
    BBadge,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notificationsSupported: false,
      notificationsPermissions: '',
      appInstalled: false,
    }
  },

  computed: {
    webNotifications: {
      get() {
        return 'web'
      },
      set(newValue) {
        this.setService(newValue, 'web')
      },
    },
    pushNotifications: {
      get() {
        return this.accountData.notification_types.includes('push') ? 'push' : false
      },
      set(newValue) {
        this.setService(newValue, 'push')
        // GlobalEventEmitter.$emit('displayPwaPrompt')
      },
    },
    smsNotifications: {
      get() {
        return this.accountData.notification_types.includes('sms') ? 'sms' : false
      },
      set(newValue) {
        this.setService(newValue, 'sms')
      },
    },
  },
  // watch: {
  //   notificationsPermissions(value) {
  //     if (value === 'granted') {
  //
  //     }
  //   },
  // },
  created() {
    window.addEventListener('appinstalled', () => {
      this.appInstalled = true
    })
    this.notificationsSupported = ('Notification' in window && 'serviceWorker' in navigator)
    this.notificationsSupported = ('Notification' in window && 'serviceWorker' in navigator)
  },
  methods: {
    notificationsEnabled() {
      if (this.notificationsSupported) {
        this.notificationsPermissions = Notification.permission
      }
      return (this.notificationsPermissions === 'granted')
    },

    requestPermission() {
      if (this.notificationsSupported) {
        // Ask permission and when granted, create new subscription
        Notification.requestPermission()
          .then(result => {
            this.notificationsPermissions = result
          })
      }
    },
    setService(value, slug) {
      if (value === false) {
        const index = this.accountData.notification_types.indexOf(slug)
        if (index > -1) {
          this.accountData.notification_types.splice(index, 1)
        }
      } else if (!this.accountData.notification_types.includes(slug)) {
        this.accountData.notification_types.push(value)
      }
    },
  },
  setup() {

  },
}
</script>
<style scoped>
#notifications .col-md-6,
#notifications .col-md-4{
  max-width: 100%;
  flex-grow: 1;
}
.desc{
  min-height: 148px;
}

</style>
