import { AbilityBuilder, Ability } from '@casl/ability'
import store from '@/store'
import { initialAbility } from '@/libs/acl/config'

export default function defineAbilityFor(user) {
  if (!user.ability) {
    return new Ability(initialAbility)
  }
  const { ability } = user
  const { can, rules } = new AbilityBuilder()

  ability.forEach(el => {
    can(el.action, el.subject, { manager_id: user.id })
    can(el.action, el.subject, { user_id: user.id })
  })
  return new Ability(rules)
}

export function defineAbilityForCurrentUser() {
  return defineAbilityFor(store.getters['account/user'])
}
