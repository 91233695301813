import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopRemoteData = () => {
  const points = ref([])
  const submittingFetch = ref(false)
  const fetchPoints = (...args) => {
    submittingFetch.value = true
    return store.dispatch('point/fetchServiceOffers', ...args).then(response => {
      submittingFetch.value = false
      return response
    })
  }

  return {
    points,
    fetchPoints,
    submittingFetch,
  }
}

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    page: 1,
    perPage: 6,
    isSortDirDesc: true,
    pointPurposes: [],
    userId: null,
    postStatuses: [],
    order: 'desc',
  })

  const filterOptions = {
    perPage: [6, 9, 24, 72],
    pointPurposes: [],
    useId: null,
    postStatuses: [],
  }

  // Sorting
  const sortBy = ref({ text: 'Update date', value: 'update_date' })
  const sortByOptions = [
    { text: 'Update date', value: 'update_date' },
  ]
  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'list-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalPoints = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalPoints,
  }
}
