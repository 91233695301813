<template>
  <div
    id="avatar"
  >
    <b-row class="d-flex align-items-center justify-content-center">
      <b-col
        class="text-center"
        cols="12"
      >
        <a @click="uploadFile">
          <b-avatar
            size="128"
            :src="image.src"
          />
        </a>
      </b-col>
    </b-row>
    <b-modal
      id="avatar-cropper"
      ref="avatar-cropper"
      centered
      size="sm"
      :title="$t('')"
      hide-footer
      :hide-header-close="avatarChanged"
    >
      <cropper
        ref="cropper"
        :canvas="{
          height: 512,
          width: 512
        }"
        stencil-component="circle-stencil"
        class="cropper"
        :src="image.src"
        @change="change"
      />
      <b-row>
        <b-col>
          <input
            id="uploadAvatar"
            ref="file"
            type="file"
            accept="image/*"
            @change="uploadImage($event)"
          >
          <b-button

            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="outline-primary"
            class="mt-1"
            @click="$refs.file.click()"
          >
            <feather-icon
              class="mr-50"
              icon="UploadIcon"
            />
            <span class="align-middle">{{ $t('Upload') }}</span>
          </b-button>
          <b-button
            v-if="avatarChanged"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1"
            block
            @click="cropImage()"
          >
            <feather-icon
              class="mr-50"
              icon="SaveIcon"
            />
            <span class="align-middle">{{ $t('Save') }}</span>
          </b-button>
          <b-button
            v-if="accountData.avatar"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="mt-1"
            block
            @click="deleteAvatar"
          >
            <feather-icon
              class="mr-50"
              icon="Trash2Icon"
            />
            <span class="align-middle">{{ $t('Delete') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import {
  BModal, VBModal, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { showToast } from '@/mixins/notification/toasts'

export default ({
  components: {
    Cropper,
    BModal,
    BButton,
    BAvatar,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: {
        src: '',
        type: 'image/jpeg',
      },
      avatarChanged: false,
    }
  },
  watch: {
    'accountData.avatar': {
      handler(value) {
        this.image.src = value
      },
    },
  },

  methods: {
    uploadFile() {
      this.$refs['avatar-cropper'].show()
    },
    change(result) {
      this.avatarChanged = true
    },
    deleteAvatar() {
      store.dispatch('account/deleteAvatar', { id: this.accountData.id }).then(response => {
        if ([200, 201, 'success'].includes(response.status)) {
          store.commit('account/deleteAvatar')
          this.image.src = null
          this.$refs['avatar-cropper'].hide()
        }
        this.showToast(response, 'Avatar')
      })
    },
    cropImage() {
      const result = this.$refs.cropper.getResult()
      if (result.canvas) {
        result.canvas.toBlob(blob => {
          const formData = new FormData() // to submit as a "multipart/form-data" request
          formData.append('image', blob)

          store.dispatch('account/addAvatar', { id: this.accountData.id, img: formData }).then(response => {
            if ([200, 201, 'success'].includes(response.status)) {
              store.commit('account/setAvatar', response.data.data.avatar)
              this.image.src = response.data.data.avatar
              this.$refs['avatar-cropper'].hide()
              this.avatarChanged = false
            }
            this.showToast(response, 'Avatar')
          })
        }, 'image/jpeg', 0.90)
      }
    },
    uploadImage(event) {
      /// Reference to the DOM input element
      const { files } = event.target
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0])

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.image = {
          src: blob,
          type: files[0].type,
        }
      }
    },
  },
  unmounted() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },
})
</script>

<style lang="scss" scoped>
.cropper{
  max-height: 512px;
}
input{
  display: none;
}
</style>
